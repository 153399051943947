//Large size navigation
@media screen and (min-width: $break-medium) {
	.top-banner{
		.consulting-heading{
			height:155px;
			h1{
				font-size:3.5em;
				font-style:italic;
				color:$purple;
				margin:0px;
				@include centerVertical();
				text-align:center;
			}
		}
		.navigation{
			/*background-color:$purple;*/
			/*background-color: $dark_purple;*/
			color:gray;

			.phone{
				float:right;
				font-style:italic;
				padding:10px 15px 10px 10px;
			}
		}
	}
}