//Medium size navigation
@media screen and (max-width: $break-medium) {
	.top-banner{
		.consulting-heading{
			height:150px;
			h1{
				font-size:3.5em;
				font-style:italic;
				color:$purple;
				margin:0px;
				@include centerVertical();
				text-align:center;
			}
		}
		.navigation{

			.inline-list{
				padding-left:10px;
				li{
					a{
						padding:5px 10px;
						color:white;
						text-decoration:none;
					}
				}
			}

			.phone{
				float:right;
				font-style:italic;
				padding:5px;
				padding-right:15px;
			}
		}
	}
	.home-left{
		width:300px !important;
		font-size:1em !important;
	}
}