@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
$break-medium: 900px;
$break-small: 725px;
$purple: #3b057e;
$dark_purple: #24034d;
$light_purple: #460697;
$background-color: #f0ebe8;
@import "mixins";
@import "small";
@import "medium";
@import "large";

a {
	color: white;
	text-decoration: none;

	&:hover {
		color: gray !important;

	}
}

img {
	width: 100%;
}

ol {
	padding-left: 30px !important;

	li {
		padding-left: 0;
	}
}

p em,
p small,
p strong,
li em,
li small,
li strong {
	padding: 0 !important;
}

body {
	font-family: 'Mercury SSm A', 'Mercury SSm B', Georgia, serif;
	background-color: #333;
}

.container-fluid {
	padding: 0 !important;
}

.clear {
	clear: both;
}

.mobile-drop {
	display: none;
}

.mobile-logo {
	display: none;
	// width: 80% !important;
	height: 100% !important;
	width: auto !important;

	float: left;
	position:relative;
}

.top-banner {
	background-position: center;
	background-image: url('../img/banner/topBanner-w9.png');
	background-size: 100% auto;
	background-color: white;
	background-repeat: no-repeat;

	h1 a {
		font-size: 1.5em;
		text-decoration: none;
		color: white;

		&:hover {
			color: white !important;
		}
	}
	border-bottom: 1px solid gold;
}

@media screen and (max-width: 1073px) {
	.top-banner {
		background-size: 100% auto;
	}
}

@media screen and (max-width: 425px) { //MOBILE LG
	.imara-logo {
		margin-top:15px !important;
		margin-bottom:15px !important;
	}
	.row div {
		margin-top:15px !important;
	}
	.navigation {
		ul li a:hover {
			background-color: $dark_purple !important;
		}
	}
}

.content-wrapper {
	background-color: $background-color;

	div {
		margin-right: 0;

		div {
			* {
				padding: 15px;
			}

			p {
				padding-bottom: 0;
			}

			img {
				padding: 0 !important;
				margin-bottom: 0;
			}
		}
	}

	ul li {
		list-style: none;
		list-style-position: outside;
		padding-left: 50px;
		padding-top: 0;

		&:before {
			margin-right: 5px;
			position: relative;
			top: 25px;
			right: 40px;
			display: block;
			content: url('../img/glyph/pyramid-list-xs.png');
		}
	}

	h1,
	h2 {
		color: $dark_purple;
		padding: 10px 15px;
		margin: 0;
	}

	h2 {
		font-size: 1.4em;
	}
}

.footer {
	color: white;
	height: 90px;
	margin-right: 0;
	border-top: 1px solid gold;

	.copy {
		padding: 15px;
	}

	.inline-list {
		padding-left: 0;
		padding-top: 30px;
		font-style: italic;

		li {
			text-decoration: underline;

			a {
				padding-bottom: 0 !important;
			}
		}
	}
}

.inline-list {
	margin: 0;
	padding-left: 15px;

	li {
		float: left;
		list-style: none;

		a {
			display: block;
			padding: 10px 15px;
			color: white;
			text-decoration: none;
		}
	}
}

ul.feature-text {
	padding-top: 0 !important;

	li {
		margin-top: -30px;
		margin-bottom: -15px;
	}
}

.feature-text {
	color: $dark_purple;
	font-size: 1.3em;
	font-style: italic;
	padding-top: 70px;

	a {
		color: $dark_purple;
		margin: 0 !important;
		padding: 0 !important;
	}

	.phone {
		font-size: 1.5em;
		padding: 15px 0;
		margin-top: 15px;
	}
}

.center-vertical {
	@include centerVertical();
}

ul.testimonials {
	li {
		div {
			text-align: right;
			font-weight: bold;
			font-style: italic;
			width: auto;
		}
	}
}

.navigation {
	font-size: 1.1em;

	a {
		color: rgb(97, 97, 97) !important;
	}

	ul li a:hover {
		color: white !important;
		background-color: #d0cece;
	}

	.active {
		color: white !important;
		background-color: $dark_purple;
	}
}

.purple-panel {
	background-color: $dark_purple;
	color: white;
	padding: 0;

	img {
		padding: 0;
	}
}
/*LOGOS*/
.imara-logo {
	width: 250px;
	margin: 0 15px;
}

.tri-braid {
	background-image: url('../img/glyph/tribraid-repeating.png');
	background-size: auto 20px;
	height: 20px;
	margin-bottom: 2px;
}

.newsLogos {
	background-color: $dark_purple;
	padding: 0 45px 45px !important;
	text-align: center;
	color: white;
}

.sideHeading{
	background-color:$dark_purple !important;
	color:white;
	border-bottom:1px solid white;
}

.expertise-nav {
	text-align:center;
	background-color:#c5c5c5;
	// background-color:gold;
	ul{
		.active {
			color: #616161  !important;
			background-color: $dark_purple;
		}
		a{
			color:$dark_purple;
			&:hover{
				color:$light_purple;
			}
			li{
				padding-top:5px;
				padding-bottom:5px;
				&:before {
					content: "" !important;
				}
				list-style:none;
			}
		}
	}
}
