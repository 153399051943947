//Small size
@media screen and (max-width: $break-small) {
	.container-fluid{
		padding:0px !important;
	}
	
	.content-wrapper{
		div {
			padding-right:0px;
		}
		h1, h2{
			margin:0 auto;
			text-align:center;
			display:block;
			padding:10px 15px;
			margin-top:15px;
		}
	}

	.top-banner{
		background-image: none !important;
		.consulting-heading{
			height:50px !important; 
			h1{ display:none; }
			.mobile-drop{
				display:block;
				float:right;
				height:20px;
				width:auto;
				@include centerVertical();
				padding-right:15px;
			}

			.mobile-logo{
				display:block;
				float:left;
				height:50px;
				padding:5px;
				width:auto;
 			}			
		}

		.navigation{
			background-color:$purple;
			min-height:15px;
			position:relative;
			z-index:1000;
			height:auto; width:100%;

			a{
				color:white !important;
			}

			.inline-list{
				display:none;
				color:white !important;
				li{
					float:none;
					padding:10px 10px;
					border-bottom: 1px solid black;
				}
			}

			.phone{
				display:none;
			}
		}
	}
	.home-wrapper{
		background-image:none !important;
		padding:0px 15px;

		.home-left{
			background-color:$purple;
			width:100% !important;

			div, h3{
				color:white !important;
				padding:0px 15px;
			}
		}
	}

	.feature-text {
		padding-top:0px !important;
		
	}	
}
